html {
  min-height: 100%;
  position: relative;
  font-size: 16px;
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  outline: none;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background: #FFFFFF;
  font-size: 1rem;
  font-family: "Arial", "Helvetica", sans-serif;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #000;
  flex-direction: column;
}

h1 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2em;
}

h2 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.2em;
}

h3 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2em;
}

h4 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2em;
}

h5 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2em;
}

h6 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2em;
}

button, select {
  text-transform: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

.pointer {
  cursor: pointer;
}

a, a:link, a:active, a:visited {
  transition: color 0.25s ease-out;
  -webkit-transition: color 0.25s ease-out;
  -moz-transition: color 0.25s ease-out;
  -o-transition: color 0.25s ease-out;
  color: #0067bd;
  text-decoration: none;
}

a:hover {
  color: #005195;
}

hr {
  margin: 5px 0;
  height: 1px;
  border: 0;
  width: 100%;
  background-color: #F0F0F0;
}

hr.other {
  background-color: #D1D2D8;
  height: 2px;
  margin-bottom: 10px;
}

.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

#main {
  flex-direction: column;
}

#content {
  flex-grow: 1;
  flex-shrink: 0;
}

#footer {
  flex-shrink: 0;
  margin: 15px 0;
  text-align: right;
  color: #4a4a4a;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: calc(3rem + 2px);
  line-height: 1.5;
  color: #4F5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 10px;
}

.form-control.error {
  color: #712c18;
  border-color: #da542e;
}

.form-control[readonly], .form-control[disabled] {
  background-color: #d2d2d2;
  cursor: not-allowed;
}

textarea.form-control {
  height: unset;
  resize: vertical;
}

select:disabled {
  background: #d2d2d2;
  cursor: not-allowed;
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  font-size: 1rem;
}

.filters .form-control {
  height: calc(2.5rem + 2px);
}

.filters .form .btn {
  height: 2.5rem;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group-append, .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  margin-top: 0;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4F5467;
  text-align: center;
  white-space: nowrap;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 10px;
}

.section-header {
  position: fixed;
  top: 0;
  padding: 18px 20px;
  width: 100%;
  font-size: 1.25rem;
  z-index: 10;
  min-height: 80px;
}

.logo a {
  color: #fff;
  text-decoration: none;
}

.logo a:hover {
  text-decoration: underline;
}

.logo img {
  max-height: 40px;
}

.section-menu {
  position: fixed;
  top: 80px;
  right: 0;
  padding: 20px;
  width: 70%;
  z-index: 11;
}

.menu-icon {
  display: inline-block;
  width: 24px;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon-top {
  width: 100%;
  display: inline-block;
  background: #fff;
  height: 3px;
}

.menu-icon-middle {
  width: 100%;
  display: inline-block;
  background: #fff;
  height: 3px;
  margin: 6px 0;
}

.menu-icon-bottom {
  width: 50%;
  display: inline-block;
  background: #fff;
  height: 3px;
}

.menu-item {
  margin-bottom: 15px;
}

.menu-item:last-child {
  margin-bottom: 0;
}

.menu-item a {
  color: #fff;
  text-decoration: none;
  position: relative;
}

.menu-item a:hover {
  text-decoration: underline;
}

.menu-item-spacer {
  display: none;
}

.link-icon {
  display: inline-block;
  margin-right: 15px;
  min-width: 20px;
}

.link-icon-right {
  display: none;
}

.container {
  padding: 0 15px 0 55px;
}

.section-overlay {
  display: none;
}

.section-content {
  flex-direction: column;
}

.section-progress-bar {
  position: relative;
}

.section-alerts {
  background: #FFFFFF;
  color: #000;
}

.alert {
  line-height: 1.2em;
  padding: 15px;
  margin-top: 1rem;
}

.alert p:first-child {
  margin-top: 0;
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert-error,
.alert-danger {
  color: #712c18;
  background-color: #f8ddd5;
  border-color: #f5cfc4;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-success {
  color: #155f3f;
  background-color: #7ed882;
  border-color: #c3ebd9;
}

.alert-warning {
  color: #856404;
  background-color: #fff1da;
  border-color: #ffeeba;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.buble {
  padding: 15px 20px;
  border: 1px solid transparent;
  background: rgb(240, 240, 240);
  margin-bottom: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.buble-title {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.buble-initials {
  display: inline-block;
  margin-right: 30px;
  background: #ffd8c2;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
  padding: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.buble:last-child {
  margin-bottom: 0;
}

.buble hr {
  margin: 15px -20px;
  border: 0;
  background: rgb(217, 217, 217);
  height: 1px;
}

.buble-note {
  background-color: #fef1e1;
  border-color: #fddbb5;
}

.buble-note hr {
  background: #fddbb5;
}

.buble-info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.buble-info hr {
  background: #bee5eb;
}

.buble-main {
  background-color: #e5f2fd;
  border-color: #bbdcfe;
}

.buble-main hr {
  background: #bbdcfe;
}

.bg-success {
  color: #FFFFFF;
  background-color: #7ed882;
}

.bg-danger {
  color: #FFFFFF;
  background-color: #f8ddd5;
}

.bg-primary {
  color: #FFFFFF;
  background-color: #cce5ff;
}

.bg-secondary {
  color: #FFFFFF;
  background-color: #e2e3e5;
}

.bg-warning {
  color: #FFFFFF;
  background-color: #fff1da;
}

.bg-info {
  color: #FFFFFF;
  background-color: #d1ecf1;
}

.bg-blue {
  color: #FFFFFF;
  background-color: #4054BE;
}

.page-login {
  color: #fff;
}

.page-login .section-header, .page-reset .section-header {
  display: none;
}

.page-login .section-footer, .page-reset .section-footer {
  display: none;
}

.page-login .section-aside, .page-reset .section-aside {
  display: none;
}

.page-login .section-main, .page-reset .section-main {
  position: relative;
  min-height: 100vh;
  margin-top: 0;
}

.page-login .form-control, .page-reset .form-control {
  font-size: 1rem;
  padding: 1rem;
}

.page-login .btn, .page-reset .btn {
  font-size: 1rem;
  padding: 1rem;
}

.page-login .section-alerts {
  margin-top: 0;
}

.page-home .section-aside {
  display: none;
}

.page-home .container {
  padding-left: 15px;
}

.page-home .section-main {
  background: #dfdfdf;
}

.page-reports .section-main {
  background: #dfdfdf;
}

.page-reports .section-alerts {
  background: #dfdfdf;
}

.page-404 .section-footer {
  display: none;
}

.page-404 .section-aside {
  display: none;
}

.section-form {
  position: relative;
}

.section-main {
  margin-top: 80px;
  background: #F0F0F0;
  min-height: calc(100vh - 80px);
}

.section-title {
  padding: 0;
  margin: 0 0 15px;
  color: #fff;
}

.section-login {
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

.section-aside {
  position: fixed;
  width: 40px;
  top: 80px;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #d3d3d3;
  overflow-y: scroll;
}

body.section-main {
  margin-top: 0;
}

.section-overlay {
  display: none;
  position: fixed;
  z-index: 15;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.overlay-content {
  min-height: 100vh;
  margin: 0 15px;
  position: relative;
  flex-direction: column;
}

.overlay-content-container {
  background: #fff;
}

.overlay-body {
  padding: 15px;
  position: relative;
}

.overlay-title {
  padding: 15px;
  border-bottom: 1px solid #D1D2D8;
}

.overlay-title h1, .overlay-title h2, .overlay-title h3, .overlay-title h4, .overlay-title h5, .overlay-title h6 {
  margin: 0;
  padding: 0;
}

.overlay-buttons {
  background: #ececec;
  width: 100%;
  padding: 10px 15px;
  text-align: right;
}

.overlay-buttons .btn {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.overlay-buttons .overlay-buttons-container a {
  margin-left: 10px;
}

.inline-overlay {
  display: none;
  position: absolute;
  top: 75px;
  z-index: 100;
  max-height: 60vh;
  background: #F0F0F0;
  padding: 0.5rem 1rem;
  max-width: 100%;
  overflow: scroll;
}

.section-pagination {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: flex-end;
}

.page-item {
  font-size: 1.125rem;
  color: #b8b8b8;
}

.page-item a, .page-item span {
  color: #b8b8b8;
  text-decoration: none;
  display: inline-block;
  padding: 8px 12px;
}

.page-active a {
  color: #4a4a4a;
}

.page-arrows {
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 1rem;
  color: #4a4a4a;
}

.page-arrows a {
  color: #4a4a4a;
}

.page-first, .page-last {
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 1rem;
  color: #4a4a4a;
  margin: 0 2px;
}

.page-first a, .page-last a {
  color: #4a4a4a;
}

.aside-item {
  text-align: center;
  margin: 25px 0;
  position: relative;
}

.aside-item a {
  color: #fff;
}

.aside-icon {
  font-size: 1rem;
  position: relative;
  z-index: 5;
  padding: 7px;
}

.aside-item a:hover .aside-icon {
  background: rgba(255, 255, 255, 0.3);
  padding: 7px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.aside-item:hover a {
  color: #8b8b8b;
}

.aside-item.active a {
  color: #6d81ea;
}

.aside-item.active .aside-icon {
  background: rgba(255, 255, 255, 0.3);
  padding: 7px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.aside-name {
  display: none;
  font-size: 0.875rem;
  position: absolute;
  left: 40px;
  top: -6px;
  background: #d3d3d3;
  padding: 6px 10px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #8b8b8b;
  white-space: nowrap;
}

.home-info {
  background: #fff;
  margin: 0 3%;
  width: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.home-info .alert {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.page-title {
  margin-top: 20px;
  margin-bottom: 25px;
  border-bottom: 2px solid #D1D2D8;
  font-weight: 300;
  font-size: 1.25rem;
  padding-bottom: 5px;
  flex-direction: column;
  align-items: baseline;
}

.page-title .page-title-link {
  text-decoration: none;
  color: inherit;
}

.page-title-button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.page-title-name {
  margin-bottom: 10px;
}

.page-title-btn {
  margin-bottom: 3px;
}

.page-title .btn {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.page-title .title-button {
  margin: 5px 0;
  display: inline-block;
}

.page-subtitle {
  border-bottom: 1px solid #D1D2D8;
  font-weight: 300;
  font-size: 1.25rem;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.form .btn {
  height: calc(3rem + 2px);
}

.form-default label {
  margin-left: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.form-half {
  max-width: 50%;
}

.form-row {
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.form-row:last-child {
  margin-bottom: 0;
}

.form-item {
  position: relative;
  width: 100%;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: unset;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.form-item-checkbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.form-item-checkbox input {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.form-item:last-child {
  margin-bottom: 0;
}

.form-item .input-group-text, .form-item .input-group-append, .form-item .input-group-prepend {
  font-size: 1.3rem;
}

.form-item-double {
  flex-grow: 2;
}

.form-item-triple {
  flex-grow: 3;
}

.form-item-forth {
  flex-grow: 4;
}

.form-item-fifth {
  flex-grow: 5;
}

.form-item-sixth {
  flex-grow: 6;
}

.form-item-full {
  flex: 0 0 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.form-heading span {
  display: block;
  padding: 5px 10px;
  background: #d2d2d2;
  margin-bottom: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-top: 20px;
}

.form-item-empty {
  display: none;
}

.form-item-double-empty {
  display: none;
  flex-grow: 2;
}

.form-item-triple-empty {
  display: none;
  flex-grow: 3;
}

.form-item-forth-empty {
  display: none;
  flex-grow: 4;
}

.form-item-fifth-empty {
  display: none;
  flex-grow: 5;
}

.form-item-sixth-empty {
  display: none;
  flex-grow: 6;
}

.form-row:last-child .form-item-last {
  margin-bottom: 0;
}

.form-item-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay-form-add-note {
  min-width: 70vw;
}

.editorRich {
  min-width: 80vw;
  min-height: 40vh;
}

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

.breadcrumb li {
  margin-right: 10px;
}

.breadcrumb li:last-child {
  margin-right: 0;
}

.breadcrumb li:last-child a {
  color: #6d81ea;
}

.breadcrumb .separator {
  color: #bebebe;
  font-size: 0.8125rem;
}

.breadcrumb .separator .fas {
  margin-top: 4px;
  display: inline-block;
}

.breadcrumb a {
  color: #979797;
}

.list-links-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.list-links {
  flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-links:first-child {
  padding-top: 15px;
}

.list-links-item {
  margin: 5px 0;
}

.list-links-item a {
  background: rgba(255, 255, 255, 0.4);
  padding: 20px 15px;
  display: inline-block;
  width: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.list-links-icon {
  font-size: 7vw;
  line-height: 1.2em;
  color: #bebebe;
  min-width: 50px;
  display: inline-block;
  text-align: center;
  margin: 0 20px;
}

.list-links-name {
  display: inline-block;
  font-size: 1.1rem;
  text-align: left;
  position: relative;
}

.list-links a {
  color: #3a3b3f;
  text-align: left;
}

.list-links a:hover .list-links-icon {
  color: #4054BE;
}

.section-stats .list-links-name {
  font-size: 1em;
}

.section-stats .list-links-wrap {
  justify-content: flex-start;
  margin: -10px -10px;
}

.section-stats .list-links:first-child {
  margin-bottom: 25px;
}

.section-stats .list-links .list-links-item {
  margin: 10px 10px;
}

.section-stats .list-links .list-links-item .list-links-icon {
  margin: 0 15px 0 0;
  min-width: initial;
  font-size: 7vw;
  line-height: 1em;
}

.section-stats .list-links .list-links-item a {
  padding: 15px 20px;
  min-width: initial;
}

.section-stats .list-links .list-links-item a.bg-blue {
  background: #4054BE;
  color: #fff;
}

.section-stats .list-links .list-links-item a.bg-blue .list-links-icon {
  color: #fff;
}

.section-stats .list-links-subitem {
  margin-top: 5px;
  text-align: center;
}

.section-stats .list-links .list-links-item hr {
  background-color: #bebebe;
}

.listing-head {
  display: none;
  background: #284255;
  color: #fff;
}

.listing-row {
  background: #fff;
  margin-bottom: 15px;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.listing-row.attention {
  background: #ffeae4;
}

.listing-title {
  min-width: 30vw;
  width: 30vw;
  display: inline-block;
  padding: 5px 15px;
  background: #284255;
  color: #fff;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  border-bottom: 1px solid #dbdbdb;
}

.listing-item.listing-right {
  overflow: visible;
}

.listing-item:first-child .listing-title {
  padding-top: 10px;
}

.listing-item:last-child .listing-title {
  padding-bottom: 10px;
}

.listing-value {
  padding: 0.375rem 0.75rem;
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.listing-value hr {
  height: 1px;
  border: 0;
  margin: 3px 0;
  width: 100%;
  background-color: #F0F0F0;
}

.listing-head .listing-value hr {
  height: 1px;
  border: 0;
  margin: 3px 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.listing-row .listing-title hr {
  margin: 5px 0;
  height: 1px;
  border: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.listing-row .listing-value hr {
  margin: 5px 0;
  height: 1px;
  border: 0;
  width: 100%;
  background-color: #bebebe;
}

.listing-value .btn {
  margin-bottom: 3px;
}

.listing-value .btn:last-child {
  margin-bottom: 0;
}

.listing-item:first-child .listing-value {
  padding-top: 10px;
}

.listing-item:last-child .listing-value {
  padding-bottom: 10px;
}

.listing-color {
  width: 100%;
  height: 60%;
  min-height: 30px;
}

.action-change-status .btn {
  color: inherit;
}

.listing-details {
  display: none;
  margin-top: -15px;
}

.listing-details .listing-row:not(:last-child) {
  margin-bottom: 0;
}

.listing-details .listing-title {
  background: unset;
  color: inherit;
}

.listing-visible {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.listing-options {
  position: absolute;
  left: 10px;
  border: 1px solid #dddbda;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 0;
  top: 100%;
  z-index: 9;
  display: none;
  background: #fff;
}

.listing-options:before {
  content: "";
  left: 0.9rem;
  margin-left: 0;
  background: #fff;
  width: 1rem;
  height: 1rem;
  position: absolute;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: -0.5rem;
  border: 1px solid #dddbda;
  border-bottom: 0;
  border-right: 0;
}

.listing-options .btn {
  display: block;
  text-align: left;
}

.listing-options .fas, .listing-options .far {
  min-width: 15px;
  text-align: center;
}

.listing-middle {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.listing-hidden {
  display: none;
}

.listing-hidden.show {
  display: initial;
}

.listing-scroll-table .listing-row {
  display: table;
}

.listing-scroll-table .listing-item {
  display: table-row;
}

.listing-scroll-table .listing-title, .listing-scroll-table .listing-value {
  display: table-cell;
  word-break: initial;
  min-width: auto;
}

.section-sortable {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.section-sortable-left {
  flex-grow: 1;
  margin-right: 2px;
  background: #fff;
}

.section-sortable-right {
  flex-grow: 1;
  margin-left: 2px;
  background: #fff;
}

.section-sortable-item {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 0;
}

.section-sortable-header {
  background: #284255;
  color: #fff;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 0;
}

.section-sortable-item-placeholder {
  background: #fff;
  box-shadow: unset;
  min-height: 50px;
}

.section-sortable-nested {
  margin-bottom: 3px;
}

.section-sortable-container-left {
  flex-grow: 1;
  margin-right: 2px;
  background: #fff;
}

.section-sortable-container-right {
  flex-grow: 1;
  margin-left: 2px;
  background: #fff;
}

.overlay-selection {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}

.overlay-selection li {
  list-style: none;
  padding: 10px;
  margin-bottom: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.overlay-selection li:hover {
  background: rgba(0, 0, 0, 0.1);
}

.cards {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.cards .card {
  margin: 15px 0;
  padding: 0 15px;
  min-width: 100%;
}

.cards .card h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.cards .card hr {
  background: #D1D2D8;
  margin-bottom: 10px;
}

.cards .card-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
}

.cards .card-notification {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(20%, -20%);
}

.cards .card-notification-container {
  position: relative;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #ff5858;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.cards .card-notification-text {
  padding: 1px 10px;
  font-size: 0.875rem;
}

.page-tasks .alert-notifications {
  padding: 0 15px;
  display: inline-block;
}

.page-tasks .cards .card {
  min-width: auto;
}

.page-tasks .card .card-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: transparent;
  border: 1px solid #712c18;
}

.page-tasks .card .card-container h2 {
  line-height: 1.4em;
}

.page-tasks .card .card-container h2 span {
  margin-right: 5px;
  line-height: 1.5em;
}

.notification-attention-buble {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(20%, -20%);
}

.notification-attention {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0;
  padding: 50% 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ff5858;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.notification-attention-text {
  padding: 5px;
  display: inline-block;
}

.notification-attention-text.no-text {
  z-index: -999999;
  visibility: hidden;
  color: #ff5858;
  font-size: 0.1rem;
}

.menu-item {
  position: relative;
}

.menu-item .notification-attention {
  width: auto;
  height: auto;
  padding: initial;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.menu-item .notification-attention-buble {
  transform: translate(0%, -100%);
}

.menu-item .notification-attention-text {
  padding: 1px 10px;
  font-size: 0.875rem;
}

.notification-bubble {
  display: inline-block;
  background: #ff5858;
  color: #fff;
  padding: 0 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 0.875rem;
}

.list-links-item .notification-attention-buble {
  transform: translate(100%, -70%);
}

.list-links-item .notification-attention {
  width: auto;
  height: auto;
  padding: initial;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.list-links-item .notification-attention-text {
  padding: 1px 10px;
  font-size: 0.875rem;
}

.contract-specific {
  margin-bottom: 15px;
}

.contract-preview {
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
}

.page-preview .section-form {
  background: #fff;
}

.preview-xml {
  background: transparent;
  border: 0;
  width: 100%;
}

.page-preview .preview-kind-XML {
  background: transparent;
}

.contract-specific [readonly=readonly] {
  pointer-events: none;
}

@media print {
  .page-preview .section-header {
    display: none;
  }

  .page-preview .section-footer {
    display: none;
  }

  .page-preview .section-aside {
    display: none;
  }

  .page-preview .section-menu {
    display: none;
  }

  .page-preview .section-main {
    margin-top: 0;
    background: #fff;
  }

  .page-preview .section-breadcrumb {
    display: none;
  }

  .page-preview .page-title {
    display: none;
  }

  .page-preview .container {
    padding-left: 0;
  }

  .page-preview-proces {
    font-size: 0.95em;
  }
}
@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
.btn-outline-cyan, .btn-outline-danger, .btn-outline-dark, .btn-outline-info, .btn-outline-light, .btn-outline-orange, .btn-outline-primary, .btn-outline-purple, .btn-outline-secondary, .btn-outline-success, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

.btn, .btn-link {
  font-weight: 400;
}

.btn-block, input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%;
}

.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-primary:hover {
  color: #fff;
  background-color: #563dea;
  border-color: #4c32e9;
}

.btn-primary.focus, .btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4c32e9;
  border-color: #4226e8;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28b779;
  border-color: #28b779;
}

.btn-success:hover {
  color: #fff;
  background-color: #219864;
  border-color: #1f8d5d;
}

.btn-success.focus, .btn-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28b779;
  border-color: #28b779;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1f8d5d;
  border-color: #1d8356;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #2255a4;
  border-color: #2255a4;
}

.btn-info:hover {
  color: #fff;
  background-color: #1b4584;
  border-color: #193f7a;
}

.btn-info.focus, .btn-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2255a4;
  border-color: #2255a4;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #193f7a;
  border-color: #173a6f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffb848;
  border-color: #ffb848;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffa922;
  border-color: #ffa415;
}

.btn-warning.focus, .btn-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffb848;
  border-color: #ffb848;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffa415;
  border-color: #ff9f08;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #da542e;
  border-color: #da542e;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c04522;
  border-color: #b54120;
}

.btn-danger.focus, .btn-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #da542e;
  border-color: #da542e;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b54120;
  border-color: #aa3d1e;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-cyan {
  color: #fff;
  background-color: #27a9e3;
  border-color: #27a9e3;
}

.btn-cyan:hover {
  color: #fff;
  background-color: #1a93ca;
  border-color: #198bbe;
}

.btn-cyan.focus, .btn-cyan:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
}

.btn-cyan.disabled, .btn-cyan:disabled {
  color: #fff;
  background-color: #27a9e3;
  border-color: #27a9e3;
}

.btn-cyan:not(:disabled):not(.disabled).active, .btn-cyan:not(:disabled):not(.disabled):active, .show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #198bbe;
  border-color: #1783b3;
}

.btn-cyan:not(:disabled):not(.disabled).active:focus, .btn-cyan:not(:disabled):not(.disabled):active:focus, .show > .btn-cyan.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
}

.btn-orange {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-orange:hover {
  color: #fff;
  background-color: #d57700;
  border-color: #c87000;
}

.btn-orange.focus, .btn-orange:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-orange:not(:disabled):not(.disabled).active, .btn-orange:not(:disabled):not(.disabled):active, .show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c87000;
  border-color: #bb6800;
}

.btn-orange:not(:disabled):not(.disabled).active:focus, .btn-orange:not(:disabled):not(.disabled):active:focus, .show > .btn-orange.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-purple:hover {
  color: #fff;
  background-color: #563dea;
  border-color: #4c32e9;
}

.btn-purple.focus, .btn-purple:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-purple:not(:disabled):not(.disabled).active, .btn-purple:not(:disabled):not(.disabled):active, .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #4c32e9;
  border-color: #4226e8;
}

.btn-purple:not(:disabled):not(.disabled).active:focus, .btn-purple:not(:disabled):not(.disabled):active:focus, .show > .btn-purple.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-outline-primary {
  color: #7460ee;
  background-color: transparent;
  border-color: #7460ee;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #7460ee;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28b779;
  background-color: transparent;
  border-color: #28b779;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28b779;
  border-color: #28b779;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28b779;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28b779;
  border-color: #28b779;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 183, 121, 0.5);
}

.btn-outline-info {
  color: #2255a4;
  background-color: transparent;
  border-color: #2255a4;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #2255a4;
  border-color: #2255a4;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #2255a4;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #2255a4;
  border-color: #2255a4;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(34, 85, 164, 0.5);
}

.btn-outline-warning {
  color: #ffb848;
  background-color: transparent;
  background-image: none;
  border-color: #ffb848;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffb848;
  border-color: #ffb848;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffb848;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb848;
  border-color: #ffb848;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 72, 0.5);
}

.btn-outline-danger {
  color: #da542e;
  background-color: transparent;
  border-color: #da542e;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #da542e;
  border-color: #da542e;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #da542e;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #da542e;
  border-color: #da542e;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(218, 84, 46, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-cyan {
  color: #27a9e3;
  background-color: transparent;
  border-color: #27a9e3;
}

.btn-outline-cyan:hover {
  color: #fff;
  background-color: #27a9e3;
  border-color: #27a9e3;
}

.btn-outline-cyan.focus, .btn-outline-cyan:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
}

.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
  color: #27a9e3;
  background-color: transparent;
}

.btn-outline-cyan:not(:disabled):not(.disabled).active, .btn-outline-cyan:not(:disabled):not(.disabled):active, .show > .btn-outline-cyan.dropdown-toggle {
  color: #fff;
  background-color: #27a9e3;
  border-color: #27a9e3;
}

.btn-outline-cyan:not(:disabled):not(.disabled).active:focus, .btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-cyan.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(39, 169, 227, 0.5);
}

.btn-outline-orange {
  color: #fb8c00;
  background-color: transparent;
  border-color: #fb8c00;
}

.btn-outline-orange:hover {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange.focus, .btn-outline-orange:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #fb8c00;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled).active, .btn-outline-orange:not(:disabled):not(.disabled):active, .show > .btn-outline-orange.dropdown-toggle {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange:not(:disabled):not(.disabled).active:focus, .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-orange.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-outline-purple {
  color: #7460ee;
  background-color: transparent;
  border-color: #7460ee;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-purple.focus, .btn-outline-purple:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #7460ee;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled).active, .btn-outline-purple:not(:disabled):not(.disabled):active, .show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-link, .btn-link:hover {
  background-color: transparent;
}

.btn-link.focus, .btn-link:focus, .btn-link:hover {
  text-decoration: underline;
  border-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled).active:focus, .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-purple.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 96, 238, 0.5);
}

.btn-link {
  color: #7460ee;
}

.btn-link:hover {
  color: #381be7;
}

.btn-link.focus, .btn-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 1px;
}

.btn-block {
  display: block;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  color: #FFFFFF;
}

a.btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.btn i, .btn svg {
  margin-right: 10px;
}

.btn:hover {
  color: #525151;
}

.btn-lg {
  padding: 0.75rem 1rem;
}

.btn-full {
  width: 100%;
}

.btn-default, a.btn-default {
  background-color: #4054BE;
  color: #FFFFFF;
}

.btn-default:hover {
  background-color: #005195;
  color: #FFFFFF;
}

.btn-view, a.btn-view {
  background: #ff9f45;
  color: #fff;
}

.btn-view:hover {
  background: #ff8d22;
  color: #fff;
}

.btn-blue, a.btn-blue {
  background-color: #0067bd;
  color: #FFFFFF;
}

.btn-blue:hover {
  background-color: #0067bd;
  color: #FFFFFF;
}

.btn-purple, a.btn-purple {
  background: #67215E;
}

.btn-purple:hover, a.btn-purple:hover {
  color: #fff;
  background-color: #551B4E;
}

.btn-edit, a.btn-edit {
  color: #A076CC;
  background: transparent;
  border: 1px solid #A076CC;
}

.btn-edit:hover, a.btn-edit:hover {
  border: 1px solid #A076CC;
}

.btn-price, a.btn-price {
  color: #008000;
  background: transparent;
  border: 1px solid #008000;
}

.btn-price:hover, a.btn-price:hover {
  border: 1px solid #008000;
}

.btn-inactivate, a.btn-inactivate {
  color: #902828;
  background: transparent;
  border: 1px solid #902828;
}

.btn-inactivate:hover, a.btn-inactivate:hover {
  border: 1px solid #525151;
}

.btn-activate, a.btn-activate {
  color: #3A7136;
  background: transparent;
  border: 1px solid #3A7136;
}

.btn-activate:hover, a.btn-activate:hover {
  border: 1px solid #525151;
}

.btn-details, a.btn-details {
  color: #00bce0;
  background: transparent;
  border: 1px solid #00bce0;
}

.btn-details:hover, a.btn-details:hover {
  border: 1px solid #525151;
}

.btn-details-fill, a.btn-details-fill {
  background: #00bce0;
  color: #fff;
}

.btn-details-fill:hover, a.btn-details-fill:hover {
  color: #fff;
  background: #0097b4;
}

.btn-delete, a.btn-delete {
  color: red;
  background: transparent;
  border: 1px solid red;
}

.btn-delete:hover, a.btn-delete:hover {
  border: 1px solid #525151;
}

.btn-status, a.btn-status {
  color: #ff9f45;
  background: transparent;
  border: 1px solid #ff9f45;
}

.btn-status:hover, a.btn-status:hover {
  border: 1px solid #525151;
}

.btn-status-fill, a.btn-status-fill {
  background: #ff9f45;
  color: #fff;
}

.btn-status-fill:hover, a.btn-status-fill:hover {
  background: #ff8d22;
  color: #fff;
}

.btn-users-fill, a.btn-users-fill {
  color: #0067bd;
  background: transparent;
  border: 1px solid #0067bd;
}

.btn-users-fill:hover, a.btn-users-fill:hover {
  border: 1px solid #0400bd;
}

.btn-assign-fill, a.btn-assign-fill {
  color: #fff;
  background: #008c1c;
  border: 0;
}

.btn-assign-fill:hover, a.btn-assign-fill:hover {
  border: 0;
  background: #00bd26;
  color: #fff;
}

.btn-reset, a.btn-reset {
  color: #979797;
  background: transparent;
  border: 1px solid #aea9a6;
}

.btn-reset:hover, a.btn-reset:hover {
  background: transparent;
  color: #000;
  border: 1px solid #aea9a6;
}

.btn-done, a.btn-done {
  color: #0CBABA;
  background: transparent;
  border: 1px solid #0CBABA;
}

.btn-done:hover, a.btn-done:hover {
  border: 1px solid #0CBABA;
}

.btn-note, a.btn-note {
  color: #b634bb;
  background: transparent;
  border: 1px solid #b634bb;
}

.btn-note:hover, a.btn-note:hover {
  border: 1px solid #525151;
}

.btn-group {
  color: #495057;
  cursor: pointer;
}

.btn-group:hover {
  color: #4054BE;
}

.btn-assign, a.btn-assign {
  color: #0067bd;
  background: transparent;
}

.btn-users, a.btn-users {
  color: #858AE3;
  background: transparent;
}

.btn-add, a.btn-add {
  color: #01BAEF;
  background: transparent;
}

.btn-forms, a.btn-forms {
  color: #0CBABA;
  background: transparent;
}

.btn-leads, a.btn-leads {
  color: #0CBABA;
  background: transparent;
}

.btn-fields, a.btn-fields {
  color: #0CBABA;
  background: transparent;
}

.btn-simple, a.btn-simple {
  color: #525151;
  background: transparent;
}

.btn-darkgrey, a.btn-darkgrey {
  color: #fff;
  background: #8b8b8b;
}

.btn-filter, a.btn-filter {
  color: #4054BE;
  background: transparent;
  border: 2px solid #4054BE;
}

.btn-darkgrey, a.btn-darkgrey:hover {
  color: #000;
}

.btn-none, a.btn-none {
  padding: 0;
}

.btn-border-less, a.btn-border-less {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.btn-border-none, a.btn-border-none {
  border: 0;
}

.btn-border-none:hover, a.btn-border-none:hover {
  border: 0;
}

.action-options {
  border: 1px solid #dddbda;
  color: #979797;
}

.action-options i, .action-options svg {
  margin-right: 0;
}

.btn-no-padding i, .btn-no-padding svg {
  margin-right: 0;
}

.title-button .btn, .title-button a.btn {
  background: transparent;
  color: #979797;
  border: 1px solid #aea9a6;
}

.title-button .btn:hover, .title-button a.btn:hover {
  background: transparent;
  color: #000;
  border: 1px solid #aea9a6;
}

.btn.generic-btn {
  height: auto;
}

.combine {
  position: relative;
}

.combine i, .combine svg {
  z-index: 1;
}

.combine .combine-over {
  position: absolute;
  top: 4px;
  right: -6px;
  color: #F0F0F0;
  font-size: 0.5rem;
  z-index: 10;
}

/* ================= START - NEW LOOK =================*/
.title-button .btn-assign-fill i, .title-button a.btn-assign-fill i {
  color: #008c1c;
}

.title-button .btn-status-fill i, .title-button a.btn-status-fill i {
  color: #ff8d22;
}

.title-button .btn-details-fill i, .title-button a.btn-details-fill i {
  color: #00bce0;
}

.title-button .btn-default i, .title-button a.btn-default i {
  color: #6d81ea;
}

.listing-value .btn, .listing-value a.btn {
  background: transparent;
  color: #979797;
  border: 1px solid #aea9a6;
}

.listing-value .btn:hover, .listing-value a.btn:hover {
  background: transparent;
  color: #000;
  border: 1px solid #aea9a6;
}

.listing-value .btn-assign i, .listing-value a.btn-assign i {
  color: #0067bd;
}

.listing-value .btn-blue i, .listing-value a.btn-blue i {
  color: #0067bd;
}

.listing-value .btn-view i, .listing-value a.btn-view i {
  color: #ff8d22;
}

.listing-value .btn-users i, .listing-value a.btn-users i {
  color: #858AE3;
}

.listing-value .btn-add i, .listing-value a.btn-add i {
  color: #01BAEF;
}

.listing-value .btn-forms i, .listing-value a.btn-forms i {
  color: #0CBABA;
}

.listing-value .btn-leads i, .listing-value a.btn-leads i {
  color: #0CBABA;
}

.listing-value .btn-fields i, .listing-value a.btn-fields i {
  color: #0CBABA;
}

.listing-value .btn-inactivate i, .listing-value a.btn-inactivate i {
  color: #902828;
}

.listing-value .btn-activate i, .listing-value a.btn-activate i {
  color: #3A7136;
}

.listing-value .btn-edit i, .listing-value a.btn-edit i {
  color: #A076CC;
}

.listing-value .btn-price i, .listing-value a.btn-price i {
  color: #008000;
}

.listing-value .btn-details i, .listing-value a.btn-details i {
  color: #00bce0;
}

.listing-value .btn-delete i, .listing-value a.btn-delete i {
  color: red;
}

.listing-value .btn-status i, .listing-value a.btn-status i {
  color: #ff9f45;
}

.listing-value .btn-done i, .listing-value a.btn-done i {
  color: #0CBABA;
}

.listing-value .btn-todo i, .listing-value a.btn-todo i {
  color: #ff8d22;
}

.listing-value .btn-finish i, .listing-value a.btn-finish i {
  color: #3db314;
}

.listing-value .btn-assign-fill i, .listing-value a.btn-assign-fill i {
  color: #008c1c;
}

.listing-value .btn-note i, .listing-value a.btn-note i {
  color: #b634bb;
}

.section-progress-bar .btn-view i, .section-progress-bar a.btn-view i {
  color: #ff8d22;
}

.section-progress-bar .btn-view {
  background: transparent;
  color: #979797;
  border: 1px solid #aea9a6;
}

.section-progress-bar .btn:hover, .section-progress-bar a.btn:hover {
  background: transparent;
  color: #000;
  border: 1px solid #aea9a6;
}

.overlay-buttons .btn {
  background: transparent;
  color: #979797;
  border: 1px solid #aea9a6;
}

.overlay-buttons .btn:hover {
  background: transparent;
  color: #000;
  border: 1px solid #aea9a6;
}

.overlay-buttons .btn-close i, .overlay-buttons a.btn-close i {
  color: red;
}

.overlay-buttons .btn-default i, .overlay-buttons a.btn-default i {
  color: #005195;
}

.listing-value .listing-options .btn {
  border: 0;
}

.listing-value .listing-options .btn:hover {
  border: 0;
}

/* ================= END - NEW LOOK =================*/
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after, .input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text, .input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}

.input-group-text input[type=radio], .input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::after, .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

.custom-control-label::before {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-control-label::after {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-file-input:lang(en) ~ .custom-file-label::after, .custom-file-label::after {
  content: "Browse";
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center no-repeat #fff;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select-lg, .custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.custom-file-input:focus ~ .custom-file-label::after, .custom-select:focus {
  border-color: #80bdff;
}

.custom-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-file-input:disabled ~ .custom-file-label, .custom-select:disabled {
  background-color: #e9ecef;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  font-size: 125%;
}

.custom-file, .custom-file-input, .custom-file-label {
  height: calc(2.25rem + 2px);
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
}

.custom-file-label, .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-label {
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select {
    transition: none;
  }
}
input[type=file].form-control {
  font-size: 12px;
  padding-top: 14px;
}

.tox-tinymce .tox-statusbar__branding {
  display: none;
}

.pointer {
  cursor: pointer;
}

.limit-row {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cl-white {
  color: #fff;
}

.cl-red {
  color: red;
}

.cl-green {
  color: green;
}

.cursor-pointer {
  cursor: pointer;
}

.ma-0 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.ma-05 {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.ma-1 {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.ma-2 {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.ma-3 {
  margin-top: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
}

.ma-4 {
  margin-top: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 4rem;
}

.ma-5 {
  margin-top: 5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-05 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.pa-0 {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.pa-05 {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

.pa-1 {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.pa-2 {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.pa-3 {
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}

.pa-4 {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
}

.pa-5 {
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-05 {
  padding-top: 0.5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-05 {
  padding-bottom: 0.5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-05 {
  padding-right: 0.5rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-05 {
  padding-left: 0.5rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pl-5 {
  padding-left: 5rem;
}

.fs-1 {
  font-size: 1rem;
}

.fs-2 {
  font-size: 2rem;
}

.fs-3 {
  font-size: 3rem;
}

.fs-4 {
  font-size: 4rem;
}

.fs-5 {
  font-size: 5rem;
}

.tx-center {
  text-align: center;
}

.tx-left {
  text-align: left;
}

.tx-right {
  text-align: right;
}

.to-upper {
  text-transform: uppercase;
}

.circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
}

.size-1 {
  width: 1em;
  height: 1em;
}

.size-2 {
  width: 2em;
  height: 2em;
}

.size-3 {
  width: 3em;
  height: 3em;
}

.size-4 {
  width: 4em;
  height: 4em;
}

.size-5 {
  width: 5em;
  height: 5em;
}

.size-full {
  width: 100%;
  height: 100%;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.justify-content-between {
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.disp-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.disp-none {
  display: none;
}

.disp-block {
  display: block;
}

.disp-iblock {
  display: inline-block;
}

.disp-none-m {
  display: none !important;
}

.disp-none-d {
  display: block !important;
}

.disp-none-inline-m {
  display: none !important;
}

.disp-none-inline-d {
  display: inline !important;
}

.nothing {
  display: none !important;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  z-index: 99999;
}

.showing {
  display: block !important;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  z-index: 99999;
}

/* S: PROGRESS BAR */
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
}

.progress-bar .info {
  position: absolute;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translate(0, -55%);
}

.progress-bar .info p {
  font-size: 1rem;
  text-align: center;
}

@-webkit-keyframes progress1 {
  0% {
    -webkit-transform: scalex(0);
    transform: scalex(0);
    opacity: 0.5;
  }
  80% {
    -webkit-transform: scalex(1);
    transform: scalex(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scalex(1);
    transform: scalex(1);
    opacity: 1;
  }
}
@keyframes progress1 {
  0% {
    -webkit-transform: scalex(0);
    transform: scalex(0);
    opacity: 0.5;
  }
  80% {
    -webkit-transform: scalex(1);
    transform: scalex(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scalex(1);
    transform: scalex(1);
    opacity: 1;
  }
}
.progress-bar .full-progress-bar {
  position: absolute;
  top: 60%;
  left: 15px;
  right: 15px;
  height: 8px;
  background: rgba(210, 210, 210, 0.6);
  transform: translate(0, -50%);
  overflow: hidden;
}

.progress-bar .paper-progress-bar {
  position: absolute;
  top: 60%;
  left: 15px;
  right: 100%;
  height: 8px;
  transform: translate(0, -50%);
  transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
  -webkit-transition: 1s ease;
  overflow: hidden;
}

.progress-bar .paper-progress-bar:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  /*background:-webkit-linear-gradient(left, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.4) 80%, white);
  background:linear-gradient(to right, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.4) 80%, white);*/
  background: -webkit-linear-gradient(left, rgba(64, 84, 190, 0.3) 10%, rgba(64, 84, 190, 0.8) 80%, #4054BE);
  background: linear-gradient(to right, rgba(64, 84, 190, 0.3) 10%, rgba(64, 84, 190, 0.8) 80%, #4054BE);
}

#progress-bar {
  margin-top: 15px;
}

.progress-bar-main {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

.progress-bar-main .info {
  position: relative;
  top: unset;
  height: auto;
  transform: unset;
  width: auto;
  padding: 0 10px;
}

.progress-bar-main .info p {
  margin: 0;
}

.progress-bar-main .full-progress-bar {
  top: unset;
  bottom: unset;
  transform: unset;
  height: 8px;
  overflow: hidden;
  position: relative;
  margin: 0 15px;
  width: auto;
  left: 0;
  right: 0;
}

.progress-bar-main .paper-progress-bar {
  top: 0;
  bottom: unset;
  transform: unset;
  height: 8px;
  overflow: hidden;
}

.progress-bar-main .paper-progress-bar:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 8px;
  bottom: 0;
  left: 0;
  background-color: #ffc;
  -webkit-animation: moveFromTo 2s infinite;
  animation: moveFromTo 2s infinite;
  transition-timing-function: ease-in;
  box-shadow: 0 0 30px 30px #4054be;
}

.progress-bar-main.progress-bar-reverse {
  flex-direction: row-reverse;
}

@-webkit-keyframes moveFromTo {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
@keyframes moveFromTo {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
.progress-bar-main .progress-bar-line {
  width: 100%;
  position: relative;
}

/* E: PROGRESS BAR */
/* S: LOADING INDICATOR; */
.loading-indicator-percent {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
}

.loading-indicator-percent span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 50px;
  font-size: 12px;
}

.loading-indicator-percent:after {
  content: " ";
  display: block;
  width: 50px;
  height: 50px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #ff8d22;
  border-color: #4054BE transparent #ff8d22 transparent;
  animation: loading-indicator-percent 1.2s linear infinite;
}

@keyframes loading-indicator-percent {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media all and (min-width: 1024px) {
  .section-aside {
    width: 60px;
    overflow: visible;
  }

  .aside-icon {
    font-size: 1.375rem;
  }

  .aside-name {
    left: 60px;
    top: -4px;
  }

  .aside-item a:hover .aside-name {
    display: inline-block;
  }

  .container {
    padding-left: 75px;
  }

  .page-404 .container {
    padding-left: 15px;
  }

  .page-home .container {
    padding-left: 15px;
  }

  .btn-none {
    padding-right: 0.2rem;
  }

  .scroll {
    overflow-x: scroll;
  }

  .disp-none-m {
    display: block !important;
  }

  .disp-none-d {
    display: none !important;
  }

  .disp-none-inline-m {
    display: inline !important;
  }

  .disp-none-inline-d {
    display: none !important;
  }

  .menu-icon {
    display: none;
  }

  .section-menu {
    top: 0;
    width: auto;
    padding: 36px 20px 23px 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .menu-item {
    margin-bottom: 0;
    margin-left: 20px;
  }

  .menu-item:first-child {
    margin-left: 0;
  }

  .menu-item-spacer {
    display: inline-block;
    width: 40px;
  }

  .link-icon-right {
    display: inline-block;
    margin-left: 20px;
  }

  .link-icon-left {
    display: none;
  }

  .page-title {
    flex-direction: row;
  }

  .form-small {
    max-width: 40%;
  }

  .form-row {
    flex-direction: row;
  }

  .form-item {
    flex-direction: row;
    margin-bottom: 0;
  }

  .form-item-empty {
    display: block;
  }

  .form-item-double-empty {
    display: block;
  }

  .form-item-triple-empty {
    display: block;
    margin-left: 45px;
    margin-right: 45px;
  }

  .form-item-forth-empty {
    display: block;
  }

  .form-item-fifth-empty {
    display: block;
  }

  .form-item-sixth-empty {
    display: block;
  }

  .form-item-flex {
    flex-direction: column;
  }

  .home-info {
    min-width: 40vw;
    width: auto;
  }

  .list-links-wrap {
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    flex-direction: row;
  }

  .list-links {
    flex-direction: row;
    margin-bottom: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .list-links:first-child {
    padding-top: 0;
  }

  .list-links:last-child {
    margin-bottom: 0;
  }

  .list-links-icon {
    font-size: 2vw;
  }

  .list-links-item {
    flex: 1 0 30%;
    margin: 15px 1%;
    flex: unset;
  }

  .list-links-item a {
    padding: 30px;
    min-width: 20vw;
  }

  .list-links-name {
    font-size: 1.3em;
  }

  .section-stats .list-links .list-links-item .list-links-icon {
    font-size: 1.6vw;
  }

  .listing-scroll {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .listing-padding-1 {
    padding-bottom: 6rem;
  }

  .listing-padding-2 {
    padding-bottom: 3.5rem;
  }

  .listing-padding-3 {
    padding-bottom: 0.5rem;
  }

  .listing-item {
    border-bottom: 0;
  }

  .listing-item, .listing-scroll-table .listing-item {
    position: relative;
    width: 100%;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    align-items: center;
    vertical-align: middle;
  }

  .listing-item:first-child .listing-value {
    padding-top: 5px;
  }

  .listing-item:last-child .listing-value {
    padding-bottom: 5px;
  }

  .listing-item-text {
    flex-grow: 5;
  }

  .listing-scroll .listing-item {
    display: unset;
    align-self: center;
    overflow: hidden;
    align-self: stretch;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .listing-head {
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .listing-head .listing-value {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .listing-head:first-child .listing-value {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  .listing-head .listing-item {
    background: #284255;
  }

  .listing-row, .listing-scroll-table .listing-row {
    margin-top: 10px;
    margin-bottom: 0;
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 10px 0;
  }

  .listing-row:last-child {
    margin-bottom: 0;
  }

  .listing-row:nth-child(even) {
    background: #fff;
  }

  .listing-row:nth-child(odd) {
    background: #fafbfc;
  }

  .listing-row.attention:nth-child(even) {
    background: #ffd2c5;
  }

  .listing-row.attention:nth-child(odd) {
    background: #ffcabb;
  }

  .listing-value, .listing-scroll-table .listing-value {
    display: inline-block;
  }

  .listing-item-id {
    max-width: 100px;
  }

  .listing-item-data {
    white-space: nowrap;
    max-width: 170px;
  }

  .listing-scroll .listing-item-data {
    max-width: 170px;
  }

  .listing-title, .listing-scroll-table .listing-title {
    display: none;
  }

  .listing-middle {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }

  .listing-center {
    flex-grow: 1;
    justify-content: center;
  }

  .listing-center .listing-value {
    text-align: center;
  }

  .listing-details {
    margin-top: 1px;
    margin-bottom: 15px;
  }

  .listing-details .listing-item {
    background: #fff;
    align-items: flex-start;
  }

  .listing-details .listing-item:first-child .listing-title {
    padding: 0.375rem 0.75rem;
  }

  .listing-details .listing-item:first-child .listing-value {
    padding: 0.375rem 0.75rem;
  }

  .listing-details .listing-title {
    display: block;
    width: unset;
    min-width: 150px;
  }

  .listing-details .listing-row {
    box-shadow: unset;
    margin-top: 0;
  }

  .listing-details .listing-row:last-child {
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 1px 0;
  }

  .listing-details .listing-row:nth-child(odd) .listing-item {
    background: #fff;
  }

  .listing-right {
    text-align: right;
    justify-content: flex-end;
  }

  .listing-half {
    flex-grow: 0.5;
  }

  .listing-double {
    flex-grow: 2;
  }

  .listing-max {
    flex-grow: 3;
  }

  .listing-value .btn {
    margin-bottom: 0;
  }

  .listing-nowrap {
    white-space: nowrap;
  }

  .listing-head .listing-item .btn-none:hover {
    color: #fff;
    cursor: inherit;
  }

  .listing-head .listing-item:first-child .listing-value {
    padding-left: 1.5rem;
  }

  .listing-head .listing-item:last-child .listing-value {
    padding-right: 1.5rem;
  }

  .listing-row .listing-item:first-child .listing-value {
    padding-left: 1.5rem;
  }

  .listing-row .listing-item:last-child .listing-value {
    padding-right: 1.5rem;
  }

  .listing-scroll {
    display: table;
    width: 100%;
  }

  .listing-scroll .listing-head {
    display: table-row;
  }

  .listing-scroll .listing-row {
    display: table-row;
  }

  .listing-scroll .listing-item {
    display: table-cell;
    overflow: unset;
    word-break: unset;
    width: auto;
  }

  .listing-options {
    left: unset;
    right: 10px;
    border: 1px solid #dddbda;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    padding: 10px 0;
    top: 100%;
    z-index: 9;
    display: none;
    background: #fff;
  }

  .listing-options:before {
    left: unset;
    right: 0.9em;
  }

  .section-sortable {
    flex-direction: row;
  }

  .buble {
    padding: 30px 40px;
  }

  .buble hr {
    margin: 15px -40px;
  }

  .buble-initials {
    margin-bottom: 0;
  }

  .buble-title {
    flex-direction: row;
    align-items: center;
  }

  .cards .card {
    min-width: 20vw;
  }

  .cards .card-notification {
    transform: translate(50%, -50%);
  }

  .page-tasks .card .card-container h2 {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .page-tasks .card .card-container {
    flex-direction: row;
    align-items: center;
  }

  .notification-attention-buble {
    transform: translate(50%, -50%);
  }

  .menu-item .notification-attention-buble {
    transform: translate(100%, -100%);
  }
}
@media all and (min-width: 1366px) {
  .cards .card {
    min-width: 18vw;
  }
}